import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { BsFillHouseFill } from "react-icons/bs";
function Navigation() {
  return (
    <>
      <Navbar expand="sm" variant="dark" className="Navbar">
        <Container>
          <Navbar.Brand href="/">Titaux</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="NavLinks">
              <Nav.Link href="/#accueil">Accueil</Nav.Link>
              <Nav.Link
                rel="noopener"
                target="_blank"
                href="https://velocity-client.newton.ca/fr/client/journey/home?shortCode=uc66v54cto0"
              >
                Demande en-ligne
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
