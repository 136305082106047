import FormContact from "../components/formsSms";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import background from "../Images/montreal-bg.jpg";
import "./css/Home.css";
import Navbar from "../Navbar/Navbar";
import Stack from "react-bootstrap/Stack";
function App() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroudRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Container>
        <Navbar />
        <Row xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}>
          <Col className="HomeText">
            Titaux
            <Stack gap={5}>
              <Col className="HomeText2">
                Le meilleur taux hypothécaire pour vous!
              </Col>
              <Col className="HomeText2">
                Une application mobile pour obtenir votre financement simplement
                et rapidement.
              </Col>
            </Stack>
          </Col>
          <Col>
            <FormContact />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
