import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsSendFill } from "react-icons/bs";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import "./formsSms.css";

import { Form } from "react-bootstrap/";
import validator from "validator";
import { toast } from "react-toastify";
import Stack from "react-bootstrap/Stack";
// import { render } from '@react-email/render';
// import sendgrid from '@sendgrid/mail';
// import { Email } from './Email';

function FormContact() {
  //set

  const intialValues = {
    interest: "Achat",
    firstName: "",
    phone: "",
  };

  const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState("");
  const [formsValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [interest, setInterest] = useState("Achat");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showSubmit, setSubmitShow] = useState();
  const [Id] = useState("");

  useEffect(() => {
    //check error --> submit

    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      handleSubmit();
    }
  }, [formErrors]);
  //  sendgrid = require('@sendgrid/mail');
  // sendgrid.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
  // const emailHtml = render(<Email url="https://localhost:3001" />);
  function validatePhoneNumber(number) {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    return isValidPhoneNumber;
  }

  function validateEmail(email) {
    const isValidEmail = validator.isEmail(email);
    toast.info(isValidEmail);
    return isValidEmail;
  }

  function handleFirstnameChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setFirstname(e.target.value);
  }
  function handleEmailChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setEmail(e.target.value);
  }
  function handlePhoneChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setPhone(e.target.value);
  }
  function handleMessageChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setMessage(e.target.value);
  }
  function handleInterestChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setInterest(e.target.value);
  }

  async function handleSubmit() {
    const user = {
      FirstName: firstName,
      Phone: phone,
      Message: message,
      Email: email,
      Interest: interest,
    };

    try {
      setSpinner("border");
      await axios.post(process.env.REACT_APP_API + "api/forms", user);
      toast.success("Succès!");
      setShowModal(true);
    } catch (err) {
      console.log(err);
      toast.error("Erreur, essayer encore.");
    } finally {
      setSpinner("");
    }
  }
  const validationSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formsValues));
    setIsSubmitting(true);
  };

  const validate = (values) => {
    let errors = {};
    const regexletter = /^[a-zA-Z]+$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.firstName) {
      errors.firstName = "Veuillez entrer un prénom. ";
    } else if (values.firstName.length >= 25) {
      errors.firstName = "Le prénom doit avoir moins de 50 caractère(s).";
    }
    if (!values.phone) {
      errors.phone = "Veuillez entrer un numéro de téléphone.";
    } else if (!validatePhoneNumber(phone)) {
      errors.phone = "Le numéro de téléphone est invalide.";
    } else if (values.phone.length > 15) {
      errors.phone =
        "Le numéro de téléphone doit avoir moins de 15 caractère(s).";
    }
    if (!values.email) {
      errors.email = "Veuillez entrer un email.";
    } else if (!validateEmail(email) || values.email.split("@")[0].length < 1) {
      errors.email = "L'email est invalide.";
    } else if (values.email.length > 80) {
      errors.email = "L'email doit avoir moins de 80 caractère(s).";
    }
    return errors;
  };

  return (
    <Container className="FormsShape" fluid>
      {/* MODAL */}
      <Modal
        data-bs-theme="dark"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Merçi!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Votre message été envoyer avec succès!
          <br /> Veuillez regarder votre appareil!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" href="/">
            D'accord
          </Button>
        </Modal.Footer>
      </Modal>
      {/* FORM */}
      <Row className="justify-content-center">
        <Form noValidate onSubmit={validationSubmit}>
          <Stack gap={4}>
            <Form.Group controlId="validationFormik01">
              <Form.Label className="TextColor">Prénom *</Form.Label>
              <Form.Control
                className="FormColor"
                type="text"
                name="firstName"
                defaultValue={formsValues.firstName}
                onChange={handleFirstnameChange}
              />
              {formErrors.firstName && (
                <div className="error">{formErrors.firstName}</div>
              )}
              {/* <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationFormikUsername">
              <Form.Label className="TextColor">
                Numéro de téléphone *
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="FormColor"
                  as="input"
                  type="tel"
                  aria-describedby="inputGroupPrepend"
                  name="phone"
                  value={formsValues.phone}
                  onChange={handlePhoneChange}
                />
              </InputGroup>
              {formErrors.phone && (
                <div className="error">{formErrors.phone}</div>
              )}
            </Form.Group>
            <Form.Group controlId="validationFormik01">
              <Form.Label className="TextColor">Email *</Form.Label>
              <Form.Control
                className="FormColor"
                type="text"
                name="email"
                defaultValue={formsValues.email}
                onChange={handleEmailChange}
              />
              {formErrors.email && (
                <div className="error">{formErrors.email}</div>
              )}
            </Form.Group>
            <Form.Group controlId="validationFormik01">
              <Form.Label className="TextColor">Note</Form.Label>
              <InputGroup>
                <Form.Control
                  className="FormColor"
                  as="textarea"
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="message"
                  value={formsValues.message}
                  onChange={handleMessageChange}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className="TextColor">
                Vous êtes intéressé par... :
              </Form.Label>
              <Form.Select
                className="FormColor"
                aria-label="Default select example"
                onChange={handleInterestChange}
              >
                <option> Achat </option>
                <option> Refinancement </option>
                <option> Renouvellement </option>
              </Form.Select>
            </Form.Group>
            <div className="TextColor2">
              * Il est important d'entrer votre numéro de cellulaire pour
              recevoir le sms pour télécharger l'application mobile (des frais
              de sms peuvent s'appliquer selon votre fournisseur de téléphone
              cellulaire)
            </div>
            <Button className="ButtonStyle" variant="warning" type="submit">
              Envoyer &ensp;
              <BsSendFill />
              &ensp;
              <Spinner variant="dark" size="sm" animation={spinner} />
            </Button>
          </Stack>
        </Form>
      </Row>
    </Container>
  );
}

export default FormContact;
