import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Home";
import Navbar from "./Navbar/Navbar";
import Container from "react-bootstrap/Container";
function App() {
  return (
    <>
      <ToastContainer theme="colored" />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/index.html" element={<Homepage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
